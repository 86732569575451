import React, { useState, useRef, useEffect } from 'react';
import { BiSearchAlt2, BiMenu } from "react-icons/bi";
import { FiLogOut, FiUser } from "react-icons/fi";
import { FaHome } from 'react-icons/fa';
import OtherUsers from './OtherUsers';
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setAuthUser, setOtherUsers, setSelectedUser } from '../../redux/userSlice';
import { setMessages } from '../../redux/messageSlice';
import { BASE_URL } from '../..';
import Cookies from 'js-cookie';
import { motion } from 'framer-motion';

const Sidebar = () => {
    const [search, setSearch] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const { otherUsers, authUser } = useSelector(store => store.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sidebarRef = useRef(null);

    const toggleSidebar = () => setIsOpen(!isOpen);

    const logoutHandler = () => {
        Cookies.remove('user');
        Cookies.remove('token');
        localStorage.clear();
        dispatch(setAuthUser(null));
        dispatch(setMessages(null));
        dispatch(setOtherUsers(null));
        dispatch(setSelectedUser(null));
        navigate("/login");
        toast.success("You have been logged out.");
    };

    useEffect(() => {
        if (search) {
            const results = otherUsers.filter(user =>
                user.fullName.toLowerCase().includes(search.toLowerCase())
            );
            setFilteredUsers(results);
        } else {
            setFilteredUsers([]);
        }
    }, [search, otherUsers]);

    const searchSubmitHandler = (e) => {
        e.preventDefault();
        const conversationUser = otherUsers.find(user => user.fullName.toLowerCase().includes(search.toLowerCase()));
        if (conversationUser) {
            dispatch(setOtherUsers([conversationUser]));
            setSearch("");
        } else {
            toast.error("User not found!");
        }
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            {/* Toggle button for mobile view */}
            <motion.button
                onClick={toggleSidebar}
                className="p-3 text-textColor bg-gray-800 rounded-md hover:bg-gray-700 fixed top-2 left-4 z-50 lg:hidden"
            >
                {isOpen ? "X" : <BiMenu className="w-6 h-6" />}
            </motion.button>

            {/* Sidebar */}
            <motion.div
                ref={sidebarRef}
                initial={{ x: '-100%' }}
                animate={{ x: isOpen || window.innerWidth >= 1024 ? '0%' : '-100%' }}
                transition={{ type: "spring", stiffness: 300, damping: 30 }}
                className={`fixed lg:static lg:block top-0 left-0 h-full bg-gradient-to-b from-baseBackground to-gray-700 w-full lg:w-[280px] shadow-lg z-40 overflow-hidden 
                    ${isOpen ? 'w-screen' : 'w-0'}`}
            >
                <div className="flex flex-col h-full p-4">
                    
                    {/* Home Button */}
                    <button
                        onClick={() => navigate('/')}
                        className="flex items-center w-full p-3 mb-4 text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition duration-200 shadow-md"
                    >
                        <FaHome className="mr-2" />
                        Home
                    </button>
                    
                    {/* Profile Section */}
                    <div className="flex items-center p-4 bg-gray-800 rounded-lg shadow-inner mb-4">
                        <div className="w-12 h-12 rounded-full overflow-hidden border-2 border-primary">
                            <img
                                src={authUser?.profilePhoto}
                                alt="Profile"
                                className="w-full h-full object-cover"
                            />
                        </div>
                        <div className="ml-4 text-white">
                            <p className="font-semibold text-lg">{authUser?.fullName}</p>
                            <div className="flex items-center text-sm text-gray-400">
                                <FiUser className="mr-1" />
                                <span>{authUser?.role}</span>
                            </div>
                        </div>
                    </div>

                    {/* Search Input */}
                    <form onSubmit={searchSubmitHandler} className="relative mb-4">
                        <input
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className="w-full p-2 border border-gray-600 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 shadow-md"
                            type="text"
                            placeholder="Search users..."
                        />
                        <button
                            type="submit"
                            className="absolute right-2 top-1/2 transform -translate-y-1/2 p-1 text-white"
                        >
                            <BiSearchAlt2 className="w-5 h-5" />
                        </button>

                        {/* Suggestions Dropdown */}
                        {filteredUsers.length > 0 && (
                            <div className="absolute left-0 right-0 mt-1 bg-gray-800 rounded-lg shadow-lg max-h-60 overflow-y-auto z-10">
                                {filteredUsers.map((user) => (
                                    <div
                                        key={user._id}
                                        className="p-2 text-white hover:bg-blue-600 cursor-pointer"
                                        onClick={() => {
                                            dispatch(setOtherUsers([user]));
                                            setSearch("");
                                            setIsOpen(false);
                                        }}
                                    >
                                        {user.fullName}
                                    </div>
                                ))}
                            </div>
                        )}
                    </form>

                    <div className="my-2 border-b border-gray-600" />

                    {/* Other Users */}
                    <div className="flex-1 overflow-y-auto max-h-[50vh] scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800">
                        <OtherUsers onUserClick={() => setIsOpen(false)} />
                    </div>

                    {/* Logout Button */}
                    <div className="mt-4">
                        <button
                            onClick={logoutHandler}
                            className="flex items-center w-full p-3 text-white bg-red-600 rounded-lg hover:bg-red-700 transition duration-200 shadow-md"
                        >
                            <FiLogOut className="mr-2" />
                            Logout
                        </button>
                    </div>
                </div>
            </motion.div>
        </>
    );
};

export default Sidebar;
