import React from 'react';

const AboutSection = () => {
  return (
    <div className="py-12 px-6 bg-baseBackground text-titleColor">
      <h2 className="teko-unique text-4xl font-bold text-center mb-6 text-textColor">About Us</h2>
      <div className="flex flex-col md:flex-row items-center justify-between max-w-5xl mx-auto space-y-8 md:space-y-0">
        {/* Left Column */}
        <div className="flex-1 mb-6 md:mb-0 md:mr-4">
          <p className="text-lg text-center md:text-left">
            The Alumni Network connects graduates with each other and with the institution. Our mission is to support alumni in their professional endeavors and to foster a sense of community. Join us in building a brighter future together!
          </p>
          <div className="text-center md:text-left mt-8">
            <a 
              href="/about" 
              className="inline-block px-4 py-2 bg-primary text-white rounded shadow hover:bg-primaryDark transition duration-300"
            >
              Learn More
            </a>
          </div>
        </div>
        {/* Right Column */}
        <div className="flex-1 flex justify-center">
          <img 
            src="/soeconnect.png" 
            alt="Logo" 
            className="w-56 h-auto rounded-lg shadow-lg transform hover:scale-105 transition duration-300" // Adjust size and add hover effect
          />
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
