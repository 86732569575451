import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import SendInput from './SendInput';
import Messages from './Messages';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';

const MessageContainer = () => {
    const navigate = useNavigate();
    const { selectedUser, authUser, onlineUsers } = useSelector(store => store.user);
    const isOnline = onlineUsers?.includes(selectedUser?._id);

    return (
        <div className='flex h-screen bg-baseBackground'>
            {/* Sidebar for large screens, toggleable for mobile */}
            <div className='hidden lg:block w-[300px]'>
                <Sidebar />
            </div>
            
            {/* Main Chat Container */}
            <div className='flex flex-col flex-1 h-full rounded-lg shadow-lg border border-gray-900'>
                {selectedUser ? (
                    <div className='flex flex-col flex-1'>
                        {/* User Info Header */}
                        <div className='flex items-center justify-end gap-4 bg-background p-3 border-b border-gray-700'>
                            <div className='flex flex-col items-end'>
                                <p className='text-lg font-semibold text-textColor truncate'>{selectedUser?.fullName}</p>
                                <p className='text-sm text-gray-400'>{isOnline ? 'Online' : 'Offline'}</p>
                            </div>
                            <div className='relative'>
                                <div className={`w-12 h-12 rounded-full border-2 ${isOnline ? 'border-green-500' : 'border-gray-500'} overflow-hidden`}>
                                    <img 
                                        src={selectedUser?.profilePhoto} 
                                        alt="user-profile" 
                                        className="w-full h-full object-cover" 
                                    />
                                </div>
                                {isOnline && (
                                    <div className='absolute bottom-0 right-0 w-3 h-3 bg-green-500 rounded-full border-2 border-white'></div>
                                )}
                            </div>
                        </div>

                        {/* Scrollable Messages Container */}
                        <div className='flex-1 overflow-y-auto max-h-full lg:max-h-[75vh] md:max-h-[75vh] scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800'>
                            <Messages />
                        </div>
                        
                        {/* Input Field for Sending Messages */}
                        <SendInput />
                    </div>
                ) : (
                    <div className='flex flex-col justify-center items-center h-full text-center'>
                        <FaUserCircle className='text-6xl text-gray-300 mb-4' />
                        <h1 className='text-3xl sm:text-4xl text-textColor font-bold'>Hi, {authUser?.fullName}</h1>
                        <h2 className='text-xl sm:text-2xl text-gray-400'>Let's start a conversation</h2>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MessageContainer;
