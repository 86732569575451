import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaRegComments, FaUserFriends, FaHome, FaUserCircle, FaUserShield } from 'react-icons/fa';
import { HiOutlineMenu, HiOutlineX } from 'react-icons/hi';
import Cookies from 'js-cookie';
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { setAuthUser, setOtherUsers, setSelectedUser } from '../redux/userSlice';
import { setMessages } from '../redux/messageSlice';
import { BASE_URL } from '../';
import axios from 'axios';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [user, setUser] = useState(null);
    const { authUser } = useSelector(store => store.user);
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(window.scrollY);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const userCookie = Cookies.get('user');
        if (userCookie) {
            const parsedUser = JSON.parse(userCookie);
            setUser(parsedUser);
        }
    }, []);

    const handleScroll = () => {
        if (window.scrollY > lastScrollY && window.scrollY > 100) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
        setLastScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollY]);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleProfileClick = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLogout = () => {
        // Clear cookies and localStorage
        Cookies.remove('user');
        Cookies.remove('token');
        localStorage.removeItem('persist:root');
        localStorage.removeItem('loginTimestamp');
      
        // Reset Redux state
        dispatch(setAuthUser(null));
        dispatch(setMessages(null));
        dispatch(setOtherUsers(null));
        dispatch(setSelectedUser(null));
      
        // Navigate to login page
        navigate('/login');
        
        // Show a success message
        toast.success('You have been logged out.');
      };

    return (
        <nav className={`bg-baseBackground shadow-lg fixed top-0 left-0 w-full z-50 transition-transform duration-300 ${isVisible ? 'translate-y-0' : '-translate-y-full'}`}>
            <div className="container mx-auto px-4 py-3 flex justify-between items-center">
                {/* Logo */}
                <Link to="/" className="flex items-center p-2 rounded transition-transform transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent" aria-label="Home">
                    <img
                        src="/soeconnect.png"
                        alt="SOE Connect Logo"
                        className="w-14 h-auto shadow-lg hover:shadow-xl transition-shadow"
                    />
                </Link>
                {/* Desktop Links */}
                <div className="hidden md:flex items-center space-x-10">
                    <Link to="/" className="text-textColor hover:text-accent flex items-center">
                        <FaHome className="mr-1" /> Home
                    </Link>
                    <Link to="/messages" className="text-textColor hover:text-accent flex items-center">
                        <FaRegComments className="mr-1" /> Chat
                    </Link>
                    <Link to="/experience" className="text-textColor hover:text-accent flex items-center">
                        <FaUserFriends className="mr-1" /> Experiences
                    </Link>
                    <Link to="/findusers" className="text-textColor hover:text-accent flex items-center">
                        <FaUserFriends className="mr-1" /> Find User
                    </Link>
                    {user && user.role === 'admin' && (
                        <Link to="/admin" className="text-textColor hover:text-accent flex items-center">
                            <FaUserShield className="mr-1" /> Admin
                        </Link>
                    )}
                </div>
                {/* Profile and Menu Icons */}
                <div className="hidden md:flex items-center">
                    {user ? (
                        <div className="relative">
                            <button onClick={handleProfileClick} className="text-textColor hover:text-accent flex items-center">
                                {user.profilePhoto ? (
                                    <img src={user.profilePhoto} alt="user-profile" className="w-10 h-10 rounded-full shadow-lg transition-shadow mx-2" />
                                ) : (
                                    <FaUserCircle className="text-2xl mr-2 text-textColor" />
                                )}
                                <span>{user.fullName}</span>
                            </button>
                            {dropdownOpen && (
                                <div className="absolute right-0 mt-2 w-40 bg-baseBackground rounded-lg shadow-lg z-20">
                                    <Link to="/profile" className="block px-4 py-2 text-textColor hover:bg-baseBackground">Profile</Link>
                                    <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-textColor">Logout</button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Link to="/login" className="text-textColor hover:text-accent">
                            Login
                        </Link>
                    )}
                </div>
                {/* Mobile Menu Button */}
                <div className="md:hidden">
                    <button onClick={toggleMenu} className="text-textColor focus:outline-none">
                        {isOpen ? <HiOutlineX className="w-6 h-6" /> : <HiOutlineMenu className="w-6 h-6" />}
                    </button>
                </div>
            </div>

            {/* Mobile Menu */}
            {isOpen && (
                <div className="md:hidden bg-baseBackground">
                    <Link to="/" className="block px-4 py-2 text-textColor hover:bg-baseBackground flex items-center">
                        <FaHome className="mr-1" /> Home
                    </Link>
                    <Link to="/messages" className="block px-4 py-2 text-textColor hover:bg-baseBackground flex items-center">
                        <FaRegComments className="mr-1" /> Chat
                    </Link>
                    <Link to="/experience" className="block px-4 py-2 text-textColor hover:bg-baseBackground flex items-center">
                        <FaUserFriends className="mr-1" /> Experiences
                    </Link>
                    <Link to="/findusers" className="block px-4 py-2 text-textColor hover:bg-baseBackground flex items-center">
                        <FaUserFriends className="mr-1" /> Find User
                    </Link>
                    {user && user.role === 'admin' && (
                        <Link to="/admin" className="block px-4 py-2 text-textColor hover:bg-baseBackground flex items-center">
                            <FaUserShield className="mr-1" /> Admin
                        </Link>
                    )}
                    {user ? (
                        <div className="block px-4 py-2 text-textColor hover:bg-baseBackground">
                            <button onClick={handleProfileClick} className="flex items-center">
                                {user.profilePhoto ? (
                                    <img src={user.profilePhoto} alt="user-profile" className="w-8 h-8 rounded-full mx-2" />
                                ) : (
                                    <FaUserCircle className="text-xl mr-2" />
                                )}
                                <span>{user.fullName}</span>
                            </button>
                            {dropdownOpen && (
                                <div className="mt-2 w-full bg-baseBackground rounded-lg shadow-lg">
                                    <Link to="/profile" className="block px-4 py-2 text-textColor">Profile</Link>
                                    <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-textColor">Logout</button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Link to="/login" className="block px-4 py-2 text-textColor hover:bg-baseBackground">Login</Link>
                    )}
                </div>
            )}
        </nav>
    );
};

export default Navbar;
