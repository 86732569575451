import React from 'react';

const Resources = () => {
  return (
    <div className="py-12 px-6 bg-baseBackground text-textColor">
      <h2 className="teko-unique text-4xl font-bold text-center mb-6 text-textColor">Resources</h2>
      <ul className="list-disc list-inside max-w-2xl mx-auto">
        <li><a href="/job-board" className="text-primary hover:underline">Job Board</a></li>
        <li><a href="/mentorship" className="text-primary hover:underline">Mentorship Programs</a></li>
        <li><a href="/networking" className="text-primary hover:underline">Networking Opportunities</a></li>
        <li><a href="/newsletters" className="text-primary hover:underline">Newsletters</a></li>
      </ul>
    </div>
  );
};

export default Resources;
