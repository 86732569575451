import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setAuthUser } from '../../redux/userSlice';
import { BASE_URL } from '../..';
import { FaLock, FaUser, FaSignInAlt, FaSpinner, FaGoogle, FaGithub } from 'react-icons/fa'; // Importing necessary icons
import Navbar from '../Navbar';
import Cookies from 'js-cookie'; // Importing js-cookie
import { googleLogin } from './firebaseConfig'; // Import googleLogin

const Login = () => {
  const [user, setUser] = useState({
    username: '',
    password: '',
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Check cookies for user data when the component mounts
  useEffect(() => {
    const storedUser = Cookies.get('user');
    if (storedUser) {
      navigate('/'); // Redirect to home if already logged in
    }
  }, [navigate]);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(`${BASE_URL}/api/v1/user/login`, user, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      // Save user data to cookies
      Cookies.set('user', JSON.stringify(res.data), { expires: 7 }); // expires in 7 days

      navigate('/'); // Redirect after successful login
      dispatch(setAuthUser(res.data));
      toast.success('Login successful!');
    } catch (error) {
      toast.error(error.response?.data?.message || 'Login failed. Please try again.');
      console.log(error);
    } finally {
      setLoading(false);
      setUser({
        username: '',
        password: '',
      });
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const user = await googleLogin();
      // You can send the user data to your backend for registration or login
      console.log(user);
      // If you have a backend that you want to integrate with:
      // await axios.post(`${BASE_URL}/api/v1/user/google-login`, { user });

      // Update the user state and navigate
      dispatch(setAuthUser(user));
      Cookies.set('user', JSON.stringify(user), { expires: 7 });
      navigate('/');
      toast.success('Google login successful!');
    } catch (error) {
      toast.error('Google login failed, please try again.');
    }
  };

  const handleGithubLogin = () => {
    // Handle GitHub Login logic
    console.log('GitHub login clicked');
  };

  return (
    <>
      {/* <Navbar /> */}
      <div className="flex flex-col md:flex-row w-full md:w-2/3 mx-auto px-8 mt-20">
        {/* Right Column - Login Form */}
        <div className="w-full md:w-full flex items-center justify-center bg-blur-sm backdrop-filter backdrop-blur-sm bg-blue-100 bg-opacity-10">
          <div className="w-full p-8 rounded-lg shadow-md border border-gray-200 bg-opacity-80">
            <h2 className="text-2xl font-semibold text-center text-white mb-6">Login</h2>
            <form onSubmit={onSubmitHandler}>
              {/** Username Input */}
              <div className="mb-4">
                <label className="block text-lg text-white mb-1">Username</label>
                <div className="flex items-center">
                  <FaUser className="text-accent mr-2" />
                  <input
                    value={user.username}
                    onChange={(e) => setUser({ ...user, username: e.target.value })}
                    className="w-full h-12 p-3 rounded-lg bg-textColor text-white placeholder-gray-400 focus:outline-none focus:ring focus:ring-accent placeholder:text-opacity-70"
                    type="text"
                    placeholder="Username"
                    required
                  />
                </div>
              </div>

              {/** Password Input */}
              <div className="mb-4">
                <label className="block text-lg text-white mb-1">Password</label>
                <div className="flex items-center">
                  <FaLock className="text-accent mr-2" />
                  <input
                    value={user.password}
                    onChange={(e) => setUser({ ...user, password: e.target.value })}
                    className="w-full h-12 p-3 rounded-lg bg-textColor text-white placeholder-gray-400 focus:outline-none focus:ring focus:ring-accent placeholder:text-opacity-70"
                    type="password"
                    placeholder="Password"
                    required
                  />
                </div>
              </div>

              <p className="text-center my-2 text-white">
                Don't have an account?{' '}
                <Link to="/signup" className="text-accent hover:underline">
                  Sign up
                </Link>
              </p>

              {/** Submit Button */}
              <div>
                <button
                  type="submit"
                  disabled={loading}
                  className={`w-full h-12 rounded-lg ${loading ? 'bg-gray-600' : 'bg-accent hover:bg-[#eab8cd]'} text-gray-900 font-semibold transition duration-200 flex items-center justify-center`}
                >
                  {loading ? <FaSpinner className="animate-spin mr-2" /> : <FaSignInAlt className="mr-2" />}
                  {loading ? 'Logging In...' : 'Login'}
                </button>
              </div>

              {/** Auth Providers Section */}
              <div className="mt-4">
                <p className="text-center text-white mb-2">Or login with:</p>
                <div className="flex justify-center space-x-4">
                  <button
                    onClick={handleGoogleLogin}
                    className="flex items-center justify-center w-full h-12 bg-red-500 text-white rounded-lg transition duration-200 hover:bg-red-600 focus:outline-none"
                  >
                    <FaGoogle className="mr-2" />
                    Google
                  </button>
                  <button
                    onClick={handleGithubLogin}
                    className="flex items-center justify-center w-full h-12 bg-gray-800 text-white rounded-lg transition duration-200 hover:bg-gray-700 focus:outline-none"
                  >
                    <FaGithub className="mr-2" />
                    GitHub
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
