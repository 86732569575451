import React from 'react';

const alumni = [
  { id: 1, name: "John Doe", photo: "/profile.webp", bio: "CEO of TechCorp" },
  { id: 2, name: "Jane Smith", photo: "/profile.webp", bio: "Lead Engineer at InnovateX" },
];

const AlumniSpotlight = () => {
  return (
    <div className="py-12 px-6 bg-baseBackground text-textColor">
      <h2 className="teko-unique text-4xl font-bold text-center mb-6 text-textColor">Alumni Spotlight</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {alumni.map(member => (
          <div key={member.id} className="bg-white shadow-md p-4 rounded text-center">
            <img src={member.photo} alt={member.name} className="w-24 h-24 rounded-full mx-auto mb-2" />
            <h3 className="font-bold">{member.name}</h3>
            <p className="text-sm text-gray-500">{member.bio}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AlumniSpotlight;
