import React, { useState } from "react";
import { FaPlusCircle, FaSearch, FaTimes } from "react-icons/fa";
import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL } from "../..";

const ExperienceForm = () => {
    const [experienceData, setExperienceData] = useState({
        title: "Software Engineer",
        company: "ABC Corp",
        location: "San Francisco, CA",
        startDate: "",
        endDate: "",
        description: "Describe your role and contributions",
        role: "Full-Stack Developer",
        titleImage: null,
    });

    const [message, setMessage] = useState("");
    const [uploadedImageUrl, setUploadedImageUrl] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [experiences, setExperiences] = useState([]);
    const darkBackground = "bg-gray-400"; // Define your background color here

    const handleChange = (e) => {
        const { name, value } = e.target;
        setExperienceData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        setExperienceData((prev) => ({ ...prev, titleImage: e.target.files[0] }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const token = JSON.parse(Cookies.get("user") || "{}").token;
        if (!token) return;

        try {
            const formData = new FormData();
            for (const key in experienceData) {
                formData.append(key, experienceData[key]);
            }

            const response = await axios.post(`${BASE_URL}/api/v1/experiences/create/`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            setMessage(response.data.message);
            setUploadedImageUrl(response.data.image.url);

            // Reset form fields and close modal after submission
            setExperienceData({
                title: "Software Engineer",
                company: "ABC Corp",
                location: "San Francisco, CA",
                startDate: "",
                endDate: "",
                description: "Describe your role and contributions",
                role: "Full-Stack Developer",
                titleImage: null,
            });

            setShowForm(false); // Close the modal
        } catch (error) {
            console.error("Error creating experience:", error.response?.data || error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSearch = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v1/experiences/search?title=${searchQuery}`);
            setExperiences(response.data);
        } catch (error) {
            console.error("Error searching experiences:", error);
        }
    };

    return (
        <div className="experience-form mt-16 px-4 md:px-8">
          {/* Create Button */}
<div className="flex justify-center items-center mb-8">
    <button
        onClick={() => setShowForm(true)}
        className="flex items-center justify-center p-4 bg-gradient-to-r from-accent to-accentlight rounded-full shadow-xl text-titleColor transition-all duration-300 ease-in-out transform hover:scale-110 hover:shadow-2xl focus:outline-none">
        <FaPlusCircle size={30} />
        <span className="ml-3 text-lg font-semibold">Create Experience</span>
    </button>
</div>

            {/* Modal (Popup Form) */}
            {showForm && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50 py-10">
                    <div className="bg-white p-8 rounded-lg shadow-xl w-3/4 sm:w-3/4 md:w-3/4 lg:w-3/4 max-h-[80vh] overflow-y-auto">
                        {/* Close Button */}
                        <button
                            onClick={() => setShowForm(false)}
                            className="absolute top-4 right-4 text-titleColor hover:text-titleColor focus:outline-none">
                            <FaTimes size={20} />
                        </button>

                        {/* Form Content */}
                        <h2 className="text-3xl font-semibold text-center text-blue-600 text-titleColor">Add New Experience</h2>

                        <form onSubmit={handleSubmit}>
                            <div className="space-y-4">
                                <div>
                                    <label htmlFor="title" className="text-lg font-medium text-titleColor">Title</label>
                                    <input
                                        type="text"
                                        name="title"
                                        id="title"
                                        placeholder="Title"
                                        value={experienceData.title}
                                        onChange={handleChange}
                                        required
                                        className={`w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 ${darkBackground} text-titleColor`}
                                    />
                                </div>

                                <div>
                                    <label htmlFor="company" className="text-lg font-medium text-titleColor">Company</label>
                                    <input
                                        type="text"
                                        name="company"
                                        id="company"
                                        placeholder="Company"
                                        value={experienceData.company}
                                        onChange={handleChange}
                                        required
                                        className={`w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 ${darkBackground} text-titleColor`}
                                    />
                                </div>

                                <div>
                                    <label htmlFor="location" className="text-lg font-medium text-titleColor">Location</label>
                                    <input
                                        type="text"
                                        name="location"
                                        id="location"
                                        placeholder="Location"
                                        value={experienceData.location}
                                        onChange={handleChange}
                                        required
                                        className={`w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 ${darkBackground} text-titleColor`}
                                    />
                                </div>

                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <label htmlFor="startDate" className="text-lg font-medium text-titleColor">Start Date</label>
                                        <input
                                            type="date"
                                            name="startDate"
                                            id="startDate"
                                            value={experienceData.startDate}
                                            onChange={handleChange}
                                            required
                                            className={`w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 ${darkBackground} text-titleColor`}
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="endDate" className="text-lg font-medium text-titleColor">End Date</label>
                                        <input
                                            type="date"
                                            name="endDate"
                                            id="endDate"
                                            value={experienceData.endDate}
                                            onChange={handleChange}
                                            required
                                            className={`w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 ${darkBackground} text-titleColor`}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="description" className="text-lg font-medium text-titleColor">Description</label>
                                    <textarea
                                        name="description"
                                        id="description"
                                        placeholder="Describe your role and contributions"
                                        value={experienceData.description}
                                        onChange={handleChange}
                                        required
                                        className={`w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 ${darkBackground} text-titleColor`}
                                    />
                                </div>

                                <div>
                                    <label htmlFor="role" className="text-lg font-medium text-titleColor">Role</label>
                                    <input
                                        type="text"
                                        name="role"
                                        id="role"
                                        placeholder="Role"
                                        value={experienceData.role}
                                        onChange={handleChange}
                                        required
                                        className={`w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 ${darkBackground} text-titleColor`}
                                    />
                                </div>

                                <div>
                                    <label htmlFor="titleImage" className="text-lg font-medium text-titleColor">Upload Image</label>
                                    <input
                                        type="file"
                                        name="titleImage"
                                        id="titleImage"
                                        onChange={handleFileChange}
                                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>

                                <button
                                    type="submit"
                                    className={`w-full p-4 mt-4 text-lg text-white bg-accent rounded-lg hover:bg-accentlight focus:outline-none focus:ring-2 focus:ring-blue-500 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}
                                    disabled={isSubmitting}>
                                    {isSubmitting ? "Submitting..." : "Submit Experience"}
                                </button>
                            </div>
                        </form>

                        {/* Message */}
                        {message && (
                            <div className="mt-4 text-center text-xl text-titleColor">
                                <span>{message}</span>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExperienceForm;
