import React from 'react';
import { useEffect, useState } from 'react';

const HeroSection = () => {
  // State for parallax effect
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    // Update background position for parallax effect
    const handleScroll = () => setOffset(window.pageYOffset * 0.5);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="relative bg-cover h-screen" style={{ backgroundImage: "url('/fwu.jpeg')", backgroundPosition: `center ${offset}px` }}>
      <div className="flex items-center justify-center h-full bg-black bg-opacity-40">
        <div className="text-center text-white px-6 sm:px-12">
          <h1 className="text-4xl teko-unique sm:text-5xl font-extrabold leading-tight mb-4">
            Welcome to the Alumni Network!
          </h1>
          <p className="text-lg sm:text-xl mb-8 italic">
            "Connecting Past & Present for a Bright Future"
          </p>
          <button className="bg-accent hover:bg-accentlight text-white py-3 px-6 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
            Join Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
