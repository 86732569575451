import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async Thunks for API requests
export const fetchAllExperiences = createAsyncThunk(
    "experience/fetchAll",
    async () => {
        const response = await axios.get("/api/v1/experiences/all");
        
        // Sort the experiences by createdAt in descending order
        const sortedExperiences = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        
        return sortedExperiences;
    }
);


export const fetchUserExperiences = createAsyncThunk(
    "experience/fetchUser",
    async (userId) => {
        const response = await axios.get(`/api/v1/experiences/user/${userId}`);
        return response.data;
    }
);

export const createExperience = createAsyncThunk(
    "experience/create",
    async (newExperience) => {
        const response = await axios.post("/api/v1/experiences/create", newExperience);
        return response.data;
    }
);

export const updateExperience = createAsyncThunk(
    "experience/update",
    async ({ id, updatedData }) => {
        const response = await axios.put(`/api/v1/experiences/update/${id}`, updatedData);
        return response.data;
    }
);

export const deleteExperience = createAsyncThunk(
    "experience/delete",
    async (id) => {
        await axios.delete(`/api/v1/experiences/delete/${id}`);
        return id;
    }
);

export const likeExperience = createAsyncThunk(
    "experience/like",
    async (id) => {
        const response = await axios.patch(`/api/v1/experiences/like/${id}`);
        return response.data;
    }
);

export const unlikeExperience = createAsyncThunk(
    "experience/unlike",
    async (id) => {
        const response = await axios.patch(`/api/v1/experiences/unlike/${id}`);
        return response.data;
    }
);

// Experience Slice
const experienceSlice = createSlice({
    name: "experience",
    initialState: {
        experiences: [],
        loading: false,
        error: null,
    },
    reducers: {
        setExperiences: (state, action) => {
            state.experiences = action.payload;
        },
        clearExperiences: (state) => {
            state.experiences = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllExperiences.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllExperiences.fulfilled, (state, action) => {
                state.loading = false;
                state.experiences = action.payload;
            })
            .addCase(fetchAllExperiences.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                console.error("Error fetching all experiences:", action.error);
            })
            .addCase(fetchUserExperiences.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserExperiences.fulfilled, (state, action) => {
                state.loading = false;
                state.experiences = action.payload;
            })
            .addCase(fetchUserExperiences.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                console.error("Error fetching user experiences:", action.error);
            })
            .addCase(createExperience.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createExperience.fulfilled, (state, action) => {
                state.loading = false;
                state.experiences.push(action.payload);
            })
            .addCase(createExperience.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                console.error("Error creating experience:", action.error);
            })
            .addCase(updateExperience.fulfilled, (state, action) => {
                const index = state.experiences.findIndex((exp) => exp.id === action.payload.id);
                if (index !== -1) {
                    state.experiences[index] = action.payload;
                }
            })
            .addCase(updateExperience.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                console.error("Error updating experience:", action.error);
            })
            .addCase(deleteExperience.fulfilled, (state, action) => {
                state.experiences = state.experiences.filter((exp) => exp.id !== action.payload);
            })
            .addCase(deleteExperience.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                console.error("Error deleting experience:", action.error);
            })
            .addCase(likeExperience.fulfilled, (state, action) => {
                const index = state.experiences.findIndex((exp) => exp.id === action.payload.id);
                if (index !== -1) {
                    state.experiences[index] = action.payload;
                }
            })
            .addCase(likeExperience.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                console.error("Error liking experience:", action.error);
            })
            .addCase(unlikeExperience.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                console.error("Error unliking experience:", action.error);
            });
    },
});

// Exporting actions and reducer
export const { setExperiences, clearExperiences } = experienceSlice.actions;

export default experienceSlice.reducer;
