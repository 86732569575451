import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import useExperience from '../../hooks/useExperience';
import Navbar from '../Navbar';
import Footer from '../Footer';
import ExperienceList from '../pages/ExperienceList';

const Profile = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();
    const { experiences, error, modifyExperience, removeExperience } = useExperience();

    useEffect(() => {
        const userCookie = Cookies.get('user');
        if (userCookie) {
            const parsedUser = JSON.parse(userCookie);
            setUser(parsedUser);
            setFormData(parsedUser);
        } else {
            navigate('/login');
        }
        setLoading(false);
    }, [navigate]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSave = () => {
        Cookies.set('user', JSON.stringify(formData), { expires: 7 });
        setUser(formData);
        setIsEditing(false);
    };

    const handleCancel = () => {
        setFormData(user);
        setIsEditing(false);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen bg-background">
                <h2 className="text-titleColor text-2xl font-semibold">Loading profile...</h2>
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-col items-center justify-center min-h-screen bg-background py-20 ">
            <h2 className="teko-unique text-4xl font-bold text-center mb-6 text-textColor">Experiences</h2>
                <ExperienceList
                experiences={experiences}
                handleLike={modifyExperience} // Modify to handle liking/unliking
                handleUnlike={removeExperience} // Modify as per your logic
                handleUpdate={modifyExperience} // You might need a different function for updating
                handleDelete={removeExperience}
            />
            </div>
        </>
    );
};

export default Profile;
