import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaHeart, FaComment, FaShare, FaTrash } from "react-icons/fa";
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { likeExperience, unlikeExperience } from "../../../src/redux/experienceSlice";
import useGetOtherUsers from "../../hooks/useGetOtherUsers";
import Cookies from 'js-cookie';
import axios from 'axios';

const ExperienceItem = ({ experience, handleUpdate, handleDelete }) => {
    const [user, setUser] = useState(null);
    const [isLiked, setIsLiked] = useState(false);
    const [likesCount, setLikesCount] = useState(experience.likesCount || 0);
    const [comments, setComments] = useState([]); // Initialize comments as an empty array
    const [showComments, setShowComments] = useState(false); // State to toggle comments display
    const [hasMoreComments, setHasMoreComments] = useState(false); // To check if there are more comments to show
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userProfile = useGetOtherUsers(experience.user?._id);

    useEffect(() => {
        const userCookie = Cookies.get('user');
        if (userCookie) {
            const parsedUser = JSON.parse(userCookie);
            setUser(parsedUser);
        } else {
            navigate('/login');
        }
    }, [navigate]);

    useEffect(() => {
        if (user && experience.likes.includes(user._id)) {
            setIsLiked(true);
        } else {
            setIsLiked(false);
        }
    }, [user, experience.likes]);

    const handleLike = async (experienceId) => {
        try {
            const token = Cookies.get('token');
            const response = await axios.post(
                `https://fwu-soe-diary-backend.onrender.com/api/v1/experiences/like/${experienceId}`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            setLikesCount(response.data.experience.likesCount);
            setIsLiked(true);
        } catch (error) {
            console.error('Error liking experience:', error);
        }
    };

    const handleUnlike = async (id) => {
        try {
            const token = Cookies.get('token');
            const response = await axios.post(
                `https://fwu-soe-diary-backend.onrender.com/api/v1/experiences/unlike/${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            setLikesCount(response.data.experience.likesCount);
            setIsLiked(false);
        } catch (error) {
            console.error("Error unliking the experience:", error);
        }
    };

    const handleDeleteWithNotification = async (id) => {
        try {
            await handleDelete(id);
            toast.success('Experience deleted successfully!');
        } catch (error) {
            toast.error('Failed to delete experience.');
        }
    };

    const handleShare = () => {
        const shareLink = `${window.location.origin}/experience/${experience._id}`;
        navigator.clipboard.writeText(shareLink)
            .then(() => toast.success('Link copied to clipboard!'))
            .catch(() => toast.error('Failed to copy link.'));
    };

    const doubleClickHandler = () => {
        if (isLiked) {
            handleUnlike(experience._id);
        } else {
            handleLike(experience._id);
        }
    };

    const handleImageClick = () => {
        navigate(`/image-view?imgUrl=${experience.Img?.imgUrl}`);
    };

    // Fetch comments when the comment button is clicked
    const fetchComments = async () => {
        try {
            console.log('Fetching comments for experience:', experience._id); // Debug log
            const response = await axios.get(
                `https://fwu-soe-diary-backend.onrender.com/api/v1/comments/${experience._id}/comments`
            );
            console.log('API response:', response.data); // Log the full response to debug
    
            if (response.data && response.data.comments) {
                console.log('Fetched comments:', response.data.comments); // Log all the fetched comments
                setComments(response.data.comments.slice(0, 10)); // Show first 10 comments
                setHasMoreComments(response.data.comments.length > 10); // Check if there are more comments
            } else {
                setComments([]); // If no comments are found, set an empty array
            }
            
            setShowComments(prevState => !prevState); // Toggle comments visibility
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    };

    const showMoreComments = () => {
        // Load additional comments
        setComments((prevComments) => [
            ...prevComments,
            ...comments.slice(prevComments.length, prevComments.length + 10),
        ]);
        setHasMoreComments(comments.length > 10); // Check again if there are more comments
    };

    return (
        <div
            onDoubleClick={doubleClickHandler}
            className="max-w-2xl mx-auto mb-8 p-6 bg-baseBackground rounded-lg shadow-2xl transform transition duration-200 cursor-pointer border border-gray-700"
        >
            <div className="flex items-center justify-between mb-5">
                <div className="flex items-center space-x-3">
                    {userProfile?.profilePhoto ? (
                        <img
                            src={userProfile.profilePhoto}
                            alt="Profile"
                            className="w-12 h-12 rounded-full object-cover border-2 border-primary shadow-lg"
                        />
                    ) : (
                        <div className="w-12 h-12 bg-gray-300 rounded-full" />
                    )}
                    <div>
                        <h3 className="text-lg font-semibold text-textColor">{userProfile?.fullName || "Unknown User"}</h3>
                        <p className="text-sm text-titleColor">{experience.company}</p>
                    </div>
                </div>
            </div>

            {/* Render the image correctly from the Img object */}
            {experience.Img?.imgUrl && (
                <div className="mb-4 rounded-lg overflow-hidden cursor-pointer">
                    <img
                        src={experience.Img.imgUrl}  // Use Img.imgUrl for the image source
                        alt="Experience"
                        className="w-full h-48 object-cover shadow-md transition-transform duration-200 hover:scale-110"
                        onClick={handleImageClick}  // On image click, navigate to the image view page
                    />
                </div>
            )}

            <div className="mb-4">
                <h3 className="text-xl font-semibold text-titleColor mb-1">{experience.title}</h3>
                <p className="text-sm text-titleColor italic mb-3">{experience.location}</p>
                <p className="text-titleColor mb-3 whitespace-pre-wrap leading-relaxed">{experience.description}</p>
                <p className="text-xs text-gray-500">
                    {new Date(experience.startDate).toLocaleDateString()} - {new Date(experience.endDate).toLocaleDateString()}
                </p>
            </div>

            <div className="border-t border-gray-600 flex justify-between items-center pt-4 text-titleColor">
                <div className="flex space-x-6">
                    <button
                        onClick={isLiked ? () => handleUnlike(experience._id) : () => handleLike(experience._id)}
                        className="flex items-center space-x-1 transition-transform transform hover:scale-110"
                    >
                        <FaHeart className={`w-6 h-6 ${isLiked ? 'text-red-500' : 'text-gray-400'} shadow-lg`} />
                        <span>{likesCount}</span>
                    </button>
                    <button
                        onClick={fetchComments} // Toggle fetching and showing comments
                        className="flex items-center space-x-1 transition-transform transform hover:scale-110"
                    >
                        <FaComment className="w-6 h-6 text-gray-400 shadow-lg" />
                        <span>Comment</span>
                    </button>
                    <button
                        onClick={handleShare}
                        className="flex items-center space-x-1 transition-transform transform hover:scale-110"
                    >
                        <FaShare className="w-6 h-6 text-gray-400 shadow-lg" />
                        <span>Share</span>
                    </button>
                </div>
                {String(user?._id) === String(experience.user?._id) && (
                    <button
                        onClick={() => handleDeleteWithNotification(experience._id)}
                        className="text-gray-400 hover:text-red-500 transition-all transform hover:scale-110"
                    >
                        <FaTrash className="w-6 h-6" />
                    </button>
                )}
            </div>

            {/* Show comments if toggled */}
            {showComments && (
                <div className="mt-4 space-y-4">
                    {comments.length === 0 ? (
                        <p className="text-gray-400">No comments yet.</p>
                    ) : (
                        comments.map((comment) => (
                            <div key={comment._id} className="p-4 bg-gray-800 rounded-lg shadow-lg">
                                <p className="text-sm text-gray-200">{comment.text}</p>
                            </div>
                        ))
                    )}
                    {hasMoreComments && (
                        <button
                            onClick={showMoreComments}
                            className="text-blue-400 hover:underline"
                        >
                            Show more comments
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default ExperienceItem;
