import React from 'react';

const testimonials = [
  { id: 1, name: "Alice Johnson", text: "The alumni network helped me land my dream job!just testing for pushing " },
  { id: 2, name: "Mark Lee", text: "I love connecting with my classmates through events." },
];

const Testimonials = () => {
  return (
    <div className="py-12 px-6 bg-baseBackground text-textColor">
      <h2 className="teko-unique text-4xl font-bold text-center mb-6 text-textColor">Testimonials</h2>
      <div className="space-y-4">
        {testimonials.map(testimonial => (
          <div key={testimonial.id} className="bg-white p-4 rounded shadow-md">
            <p className="italic">"{testimonial.text}"</p>
            <p className="font-bold text-right">- {testimonial.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
