import { useDispatch, useSelector } from "react-redux";
import {
    fetchAllExperiences,
    fetchUserExperiences,
    createExperience,
    updateExperience,
    deleteExperience,
    likeExperience,
} from "../redux/experienceSlice.js";
import { useEffect, useCallback } from "react";

const useExperience = () => {
    const dispatch = useDispatch();
    
    // Access experience state from Redux store
    const { experiences, loading, error } = useSelector((state) => state.experience);

    // Fetch all experiences (for admin or general viewing)
    const getAllExperiences = useCallback(() => {
        dispatch(fetchAllExperiences());
    }, [dispatch]);

    // Fetch experiences of the logged-in user
    const getUserExperiences = useCallback((userId) => {
        dispatch(fetchUserExperiences(userId));
    }, [dispatch]);

    // Create a new experience
    const addExperience = useCallback((experienceData) => {
        return dispatch(createExperience(experienceData));
    }, [dispatch]);

    // Update an experience by ID
    const modifyExperience = useCallback((id, updates) => {
        return dispatch(updateExperience({ id, updatedData: updates }));
    }, [dispatch]);

    // Delete an experience by ID
    const removeExperience = useCallback((id) => {
        return dispatch(deleteExperience(id));
    }, [dispatch]);

    // Like an experience by ID
    const likeAnExperience = useCallback((id) => {
        return dispatch(likeExperience(id));
    }, [dispatch]);

    // Optionally, fetch all experiences on initial render
    useEffect(() => {
        getAllExperiences();
    }, [getAllExperiences]);

    return {
        experiences,
        loading,
        error,
        getAllExperiences,
        getUserExperiences,
        addExperience,
        modifyExperience,
        removeExperience,
        likeAnExperience,
    };
};

export default useExperience;
