import React, { useState } from "react";
import { FaHeart, FaEdit, FaTrash } from "react-icons/fa";
import useExperience from "../../hooks/useExperience";
import axios from "axios";
import Cookies from "js-cookie"; // Access cookies for token
import { BASE_URL } from "../..";
import ExperienceForm from "./ExperienceForm";
import ExperienceList from "./ExperienceList";

const ExperienceComponent = () => {
    const { experiences, loading, error, modifyExperience, removeExperience, likeAnExperience } = useExperience();

    const [newExperience, setNewExperience] = useState({
        title: "",
        company: "",
        location: "",
        startDate: "",
        endDate: "",
        description: "",
        role: "",
        images: [] // Stores uploaded image files
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewExperience((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        setNewExperience((prev) => ({ ...prev, images: e.target.files })); // Handle multiple files
    };

    const handleAddExperience = async (e) => {
        e.preventDefault();
        const token = JSON.parse(Cookies.get("user") || "{}").token;
        if (!token) return;

        try {
            const formData = new FormData();
            formData.append("title", newExperience.title);
            formData.append("company", newExperience.company);
            formData.append("location", newExperience.location);
            formData.append("startDate", newExperience.startDate);
            formData.append("endDate", newExperience.endDate);
            formData.append("description", newExperience.description);
            formData.append("role", newExperience.role);

            // Append each image file to the form data
            Array.from(newExperience.images).forEach((image, index) => {
                formData.append(`images`, image);
            });

            const response = await axios.post(`${BASE_URL}/api/v1/experiences/create/`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            // Reset the form upon success
            setNewExperience({
                title: "",
                company: "",
                location: "",
                startDate: "",
                endDate: "",
                description: "",
                role: "",
                images: []
            });
            console.log("Experience added:", response.data);

        } catch (error) {
            console.error("Error creating experience:", error.response?.data || error.message);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <p className="text-lg font-semibold animate-pulse text-secondary">Loading Experiences...</p>
            </div>
        );
    }
    if (error) {
        return <p className="text-center text-red-500">Error: {error}</p>;
    }

    return (
        <div className="flex flex-col items-center p-6 bg-gray-100 font-inter min-h-screen">
            {/* Experience Form */}
            <ExperienceForm
                newExperience={newExperience}
                handleChange={handleChange}
                handleAddExperience={handleAddExperience}
                handleFileChange={handleFileChange}
            />

            {/* Experience List */}
            <ExperienceList
                experiences={experiences}
                handleLike={likeAnExperience}
                handleUnlike={removeExperience}
                handleUpdate={modifyExperience}
                handleDelete={removeExperience}
            />
        </div>
    );
};

export default ExperienceComponent;
