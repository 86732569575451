import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { BASE_URL } from '../..';
import { FaUser, FaLock, FaMale, FaFemale, FaSignInAlt, FaSpinner, FaLink, FaFileImage } from 'react-icons/fa';

const Signup = () => {
  const [user, setUser] = useState({
    fullName: '',
    username: '',
    password: '',
    confirmPassword: '',
    gender: '',
    bio: '',
    website: '',
    profileImg: null,
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setUser({ ...user, profileImg: e.target.files[0] });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (user.password !== user.confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }

    const formData = new FormData();
    formData.append('fullName', user.fullName);
    formData.append('username', user.username);
    formData.append('password', user.password);
    formData.append('confirmPassword', user.confirmPassword);
    formData.append('gender', user.gender);
    formData.append('bio', user.bio);
    formData.append('website', user.website);
    if (user.profileImg) {
      formData.append('profileImg', user.profileImg);
    }

    setLoading(true);

    try {
      const res = await axios.post(`${BASE_URL}/api/v1/user/register`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });

      if (res.data.success) {
        navigate('/login');
        toast.success(res.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred!");
      console.error(error);
    } finally {
      setLoading(false);
      setUser({
        fullName: '',
        username: '',
        password: '',
        confirmPassword: '',
        gender: '',
        bio: '',
        website: '',
        profileImg: null,
      });
    }
  };

  return (
    <div className="flex flex-col md:flex-row w-full md:w-1/2 mx-auto px-8 mt-14">
      <div className="w-full md:w-full flex items-center justify-center bg-blue-100 bg-opacity-10 backdrop-filter backdrop-blur-sm">
        <div className="w-full p-8 rounded-lg shadow-md border border-gray-200 bg-opacity-80">
          <h2 className="text-2xl font-semibold text-center text-white mb-6">Signup</h2>
          <form onSubmit={onSubmitHandler} encType="multipart/form-data">
            {/* Full Name */}
            <div className="mb-4">
              <label className="block text-lg text-white mb-1">Full Name</label>
              <input
                type="text"
                placeholder="Full Name"
                value={user.fullName}
                onChange={(e) => setUser({ ...user, fullName: e.target.value })}
                className="w-full h-12 p-3 rounded-lg bg-gray-700 text-white placeholder-gray-400"
                required
              />
            </div>

            {/* Username */}
            <div className="mb-4">
              <label className="block text-lg text-white mb-1">Username</label>
              <input
                type="text"
                placeholder="Username"
                value={user.username}
                onChange={(e) => setUser({ ...user, username: e.target.value })}
                className="w-full h-12 p-3 rounded-lg bg-gray-700 text-white placeholder-gray-400"
                required
              />
            </div>

            {/* Password */}
            <div className="mb-4">
              <label className="block text-lg text-white mb-1">Password</label>
              <input
                type="password"
                placeholder="Password"
                value={user.password}
                onChange={(e) => setUser({ ...user, password: e.target.value })}
                className="w-full h-12 p-3 rounded-lg bg-gray-700 text-white placeholder-gray-400"
                required
              />
            </div>

            {/* Confirm Password */}
            <div className="mb-4">
              <label className="block text-lg text-white mb-1">Confirm Password</label>
              <input
                type="password"
                placeholder="Confirm Password"
                value={user.confirmPassword}
                onChange={(e) => setUser({ ...user, confirmPassword: e.target.value })}
                className="w-full h-12 p-3 rounded-lg bg-gray-700 text-white placeholder-gray-400"
                required
              />
            </div>

            {/* Gender */}
            <div className="mb-4">
              <label className="block text-lg text-white mb-1">Gender</label>
              <div className="flex items-center">
                <label className="text-white mr-4 flex items-center">
                  <input
                    type="radio"
                    value="male"
                    checked={user.gender === 'male'}
                    onChange={() => setUser({ ...user, gender: 'male' })}
                    className="mr-2"
                  />
                  <FaMale className="text-accent mr-1" />
                  Male
                </label>
                <label className="text-white flex items-center">
                  <input
                    type="radio"
                    value="female"
                    checked={user.gender === 'female'}
                    onChange={() => setUser({ ...user, gender: 'female' })}
                    className="mr-2"
                  />
                  <FaFemale className="text-accent mr-1" />
                  Female
                </label>
              </div>
            </div>

            {/* Bio */}
            <div className="mb-4">
              <label className="block text-lg text-white mb-1">Bio</label>
              <textarea
                placeholder="A short bio about yourself"
                value={user.bio}
                onChange={(e) => setUser({ ...user, bio: e.target.value })}
                className="w-full h-24 p-3 rounded-lg bg-gray-700 text-white placeholder-gray-400"
              />
            </div>

            {/* Website */}
            <div className="mb-4">
              <label className="block text-lg text-white mb-1">Website</label>
              <div className="flex items-center">
                <FaLink className="text-accent mr-2" />
                <input
                  type="url"
                  placeholder="https://yourwebsite.com"
                  value={user.website}
                  onChange={(e) => setUser({ ...user, website: e.target.value })}
                  className="w-full h-12 p-3 rounded-lg bg-gray-700 text-white placeholder-gray-400"
                />
              </div>
            </div>

            {/* Profile Image */}
            <div className="mb-4">
              <label className="block text-lg text-white mb-1">Profile Image</label>
              <div className="flex items-center">
                <FaFileImage className="text-accent mr-2" />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="w-full h-12 p-3 rounded-lg bg-gray-700 text-white"
                />
              </div>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              disabled={loading}
              className={`w-full h-12 rounded-lg ${loading ? 'bg-gray-600' : 'bg-accent hover:bg-accent-light'} text-gray-900 font-semibold transition duration-200 flex items-center justify-center`}
            >
              {loading ? <FaSpinner className="animate-spin mr-2" /> : <FaSignInAlt className="mr-2" />}
              {loading ? 'Signing Up...' : 'Signup'}
            </button>
          </form>

          <p className="text-center my-2 text-white">
            Already have an account?{' '}
            <Link to="/login" className="text-accent hover:underline">
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
