import UserProfile from './components/pages/OtherUserProfile'; // Import UserProfile component

// Other imports remain the same
import Signup from './components/Auth/Signup';
import './App.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from './components/Home/HomePage';
import Login from './components/Auth/Login';
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute component
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import io from "socket.io-client";
import { setSocket } from './redux/socketSlice';
import { setOnlineUsers } from './redux/userSlice';
import { BASE_URL } from '.';
import MessageContainer from './components/Chat/MessageContainer';
import NotFoundPage from './components/Error/NotFoundPage';
import Profile from './components/pages/Profile';
import ShareExperience from './components/pages/ShareExperience';
import Experience from './components/pages/Experience';
import FindUser from './components/pages/FindUser';

// Import react-toastify components
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast notifications
import EventsSection from './components/Home/EventsSection';
import EventManagement from './components/Home/EventManagement';
import Admin from './components/pages/Admin/Admin';
import ImageView from './components/pages/ImageView';

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />
  },
  {
    path: "/signup",
    element: <Signup />
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/profile",
    element: <Profile/>
  },
  {
    path: "/share-experience",
    element: <ShareExperience/>
  },

  {
    path: "/image-view",
    element: <ImageView/>
  },
  {
    path: "/experience",
    element:
    <ProtectedRoute>
     <Experience/>
     </ProtectedRoute>
  },
  {
    path: "/messages",
    element: (
      <ProtectedRoute>
        <MessageContainer />
      </ProtectedRoute>
    ) // Wrap MessageContainer with ProtectedRoute
  },
  {
    path: "/events",
    element: (
      <ProtectedRoute>
      <EventManagement/>
      </ProtectedRoute>
    ) // Wrap MessageContainer with ProtectedRoute
  },


  {
    path: "/admin",
    element: (
      <ProtectedRoute>
     <Admin/>
      </ProtectedRoute>
    ) // Wrap MessageContainer with ProtectedRoute
  },


  {
    path: "/findusers",
    element: (
      <ProtectedRoute>
        <FindUser/>
      </ProtectedRoute>
    ) // Wrap FindUser with ProtectedRoute
  },
  {
    path: "/users/:userId", // New route for UserProfile
    element: <UserProfile /> // Add the UserProfile component
  },
  {
    path: "*", // Catch-all route for 404 page
    element: <NotFoundPage />
  }
]);

function App() {
  const { authUser } = useSelector(store => store.user);
  const { socket } = useSelector(store => store.socket);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authUser) {
      const socketio = io(`${BASE_URL}`, {
        query: {
          userId: authUser._id
        }
      });
      dispatch(setSocket(socketio));

      socketio?.on('getOnlineUsers', (onlineUsers) => {
        dispatch(setOnlineUsers(onlineUsers));
      });

      return () => socketio.close();
    } else {
      if (socket) {
        socket.close();
        dispatch(setSocket(null));
      }
    }
  }, [authUser]);

  return (
    <div>
      <RouterProvider router={router} />
      <ToastContainer /> {/* Add the ToastContainer here */}
    </div>
  );
}

export default App;
