import React, { useEffect } from 'react'
import Posts from './Posts.jsx'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Navbar from '../Navbar'
import Footer from '../Footer'
import HeroSection from './HeroSection'
import EventsSection from './EventsSection'
import NewsletterSignup from './NewsletterSignup'
import Resources from './Resources'
import Testimonials from './Testimonials'
import AlumniSpotlight from './AlumniSpotlight'
import AboutSection from './AboutSection'

const HomePage = () => {
  const { authUser } = useSelector(store => store.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authUser) {
      navigate("/login");
    }
  }, [authUser, navigate]);

  return (
   <>
   <div className="bg-background">
      <Navbar />
      <HeroSection />
      <AboutSection/>
      <EventsSection/>
      <AlumniSpotlight/>
      <Testimonials/>
      <Resources/>
      <Posts/>
      <NewsletterSignup/>
      <Footer/>
    </div>
   </>
  )
}

export default HomePage;
