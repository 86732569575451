import React, { useState } from 'react';

const NewsletterSignup = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(''); // Reset message on new submission

    // Simulating an API call for newsletter signup
    try {
      // Replace the following line with actual signup logic
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate network delay
      console.log("Signed up:", email);
      setMessage('Thank you for signing up!');
      setEmail(''); // Clear email input
    } catch (error) {
      setMessage('Something went wrong, please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="py-12 px-6 bg-baseBackground text-textColor text-center">
      <h2 className="teko-unique text-4xl font-bold text-center mb-6 text-textColor">Stay Updated!</h2>
      <p className="mb-4">Sign up for our newsletter to get the latest updates and events.</p>
      <form onSubmit={handleSubmit} className="flex justify-center mb-4">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Your email address"
          className="border border-gray-300 p-2 rounded-l bg-hoverBackground"
          required
        />
        <button type="submit" className="bg-accent text-white py-2 px-4 rounded-r" disabled={loading}>
          {loading ? 'Subscribing...' : 'Subscribe'}
        </button>
      </form>
      {message && <p className="mt-4">{message}</p>}
    </div>
  );
};

export default NewsletterSignup;
