import React, { useState } from 'react';
import { BiSearchAlt2 } from 'react-icons/bi';
import OtherUsers from './OtherUsers';
import { useSelector, useDispatch } from 'react-redux';
import { setOtherUsers } from '../../redux/userSlice';
import toast from 'react-hot-toast';
import Footer from '../Footer';
import Navbar from '../Navbar';

const FindUser = () => {
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
    const { otherUsers, allUsers } = useSelector(store => store.user); // Access all users to reset the list

    const searchSubmitHandler = (e) => {
        e.preventDefault();
        const conversationUser = otherUsers.find(user => 
            user.fullName.toLowerCase().includes(search.toLowerCase())
        );

        if (conversationUser) {
            dispatch(setOtherUsers([conversationUser])); // Update Redux store with found user
            setSearch(""); // Clear the search input
        } else {
            toast.error("User not found!");
        }
    };

    const clearSearchHandler = () => {
        dispatch(setOtherUsers(allUsers)); // Reset to show all users
        setSearch(""); // Clear the search input
    };

    return (
        <>
            <Navbar/> 
            <div className="flex flex-col items-center justify-center min-h-screen bg-background p-4 mt-2 lg:mt-2">
                <div className="bg-baseBackground shadow-lg rounded-lg w-full sm:px-2 sm:mx-2 mt-4">
                    <h2 className="teko-unique text-4xl font-bold text-center mb-2 text-textColor mt-14">Find Users</h2>
                    
                    <form onSubmit={searchSubmitHandler} className="flex items-center gap-2 mb-4 w-2/3 mx-auto bg-baseBackground p-4 rounded-lg shadow-lg border">
    <input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="w-full p-2 pr-10 border border-gray-400 rounded-lg text-text-primary bg-baseBackground placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 transition duration-200 shadow-inner hover:shadow-lg"
        type="text"
        placeholder="Search for users..."
    />
    <button
        type="submit"
        className="flex items-center justify-center p-2 text-textColor bg-baseBackground rounded-lg hover:bg-opacity-80 transition duration-200 shadow-md transform hover:scale-105">
        <BiSearchAlt2 className="w-6 h-6" />
    </button>
    <button
        type="button"
        onClick={clearSearchHandler}
        className="flex items-center justify-center p-2 text-textColor bg-accent rounded-lg hover:bg-accentlight transition duration-200 shadow-md transform hover:scale-105">
        Clear
    </button>
</form>


                    {/* Other Users */}
                    <div className='flex-1 overflow-y-auto max-h-[70vh] scrollbar-thin scrollbar-thumb-baseBackground scrollbar-track-gray-800'>
                        <OtherUsers /> {/* Use the OtherUsers component here */}
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default FindUser;
